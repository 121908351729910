import ReactGA from 'react-ga'
import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE, GAME_URL } from '../constants/strings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean
) => {
  ReactGA.event({
    category: 'game',
    action: 'share',
    label: `day-${solutionIndex}`,
  })

  const shareText = `${GAME_TITLE} ${solutionIndex + 1} ${lost ? 'X' : guesses.length}/6${
      isHardMode ? '*' : ''
    }\n\n${generateEmojiGrid(guesses)}\n\n`;

  navigator.clipboard.writeText(`${shareText}${GAME_URL}`)

  return false
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟦'
            case 'present':
              return '🟧'
            default:
              if (localStorage.getItem('theme') === 'dark') {
                return '⬛'
              }
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
