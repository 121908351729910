export const WORDS = [
  'washer',
  'gloves',
  'rubber',
  'beam',
  'gyprock',
  'screen',
  'caulk',
  'durry',
  'award',
  'raise',
  'build',
  'civil',
  'grass',
  'mitre',
  'price',
  'slide',
  'smoko',
  'claim',
  'drain',
  'plant',
  'slabs',
  'plumb',
  'tiles',
  'dowel',
  'waste',
  'alarm',
  'pools',
  'gates',
  'blind',
  'solar',
  'depth',
  'grout',
  'panel',
  'smoke',
  'truck',
  'frame',
  'crane',
  'cable',
  'glaze',
  'gantt',
  'joint',
  'brace',
  'shelf',
  'rivet',
  'seals',
  'works',
  'chute',
  'boots',
  'float',
  'light',
  'epoxy',
  'dingo',
  'glass',
  'water',
  'tools',
  'scope',
  'vinyl',
  'level',
  'floor',
  'bench',
  'drill',
  'stone',
  'doors',
  'screw',
  'width',
  'donga',
  'paint',
  'hoist',
  'paver',
  'fence',
  'stair',
  'booms',
  'brass',
  'steel',
  'truss',
  'sheer',
  'crush',
  'admin',
  'rails',
  'walls',
  'comms',
  'drone',
  'lower',
]
